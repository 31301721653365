import styled from '@emotion/styled';
import * as Sentry from '@sentry/nextjs';

import {
  getReleasesSplashPageForVanityPath,
  ReleaseSplashPage,
} from '@app/api/resources/Releases';

import { getFullRouteUrl } from '@app/services/routeHelpers';

import { Context } from '@app/types/common';

import useI18nUrlData, {
  getI18nUrlDataFromState,
} from '@app/hooks/helpers/useI18nUrlData';

import { redirectToPath } from '@app/services/initialisation';

import AboutTheFilm from '@app/components/release/AboutTheFilm';
import ReleaseFooter from '@app/components/release/ReleaseFooter';
import ReleaseHeader from '@app/components/release/ReleaseHeader';
import WatchInCinemas from '@app/components/release/WatchInCinemas';
import WatchOnMubi from '@app/components/release/WatchOnMubi';
import SocialMetaTags from '@app/components/SocialMetaTags';
import StandardHeadTags from '@app/components/StandardHeadTags';

type ReleasePageProps = { releaseSplashPage: ReleaseSplashPage };

const ReleasePage = ({ releaseSplashPage }: ReleasePageProps) => {
  const i18nUrlData = useI18nUrlData();

  return (
    <>
      <style global jsx>{`
        html,
        body,
        #__next {
          height: 100%;
        }
      `}</style>
      <StandardHeadTags title={releaseSplashPage.film_title} />
      <SocialMetaTags
        title={releaseSplashPage.film_title}
        description={releaseSplashPage.about_the_film}
        url={getFullRouteUrl({
          url: `/r/${releaseSplashPage.vanity_path}`,
          i18nUrlData,
        })}
        image={releaseSplashPage.mobile_background_image}
      />
      <Container>
        <ReleaseHeader releaseSplashPage={releaseSplashPage} />
        <AboutTheFilm releaseSplashPage={releaseSplashPage} />
        {!releaseSplashPage.is_expired ? (
          <WatchInCinemas releaseSplashPage={releaseSplashPage} />
        ) : null}
        {releaseSplashPage.release.has_programming ? (
          <WatchOnMubi releaseSplashPage={releaseSplashPage} />
        ) : null}
        <ReleaseFooter />
      </Container>
    </>
  );
};

type ReleaseInitialProps =
  | { releaseSplashPage: ReleaseSplashPage }
  | { errorStatusCode: number };

ReleasePage.getInitialProps = async (
  ctx: Context,
): Promise<ReleaseInitialProps> => getInitialProps(ctx);

export const getInitialProps = async (
  ctx: Context,
): Promise<ReleaseInitialProps> => {
  const { store, query } = ctx;
  const currentState = store.getState();
  const httpContext = currentState?.appState?.httpContext;

  const releaseVanityPath = query.releaseVanityPath || query.vanityPath;

  try {
    const { data: releaseSplashPage } =
      await getReleasesSplashPageForVanityPath(
        httpContext,
        releaseVanityPath as string,
      );

    const redirectToFilmPage =
      (releaseSplashPage.is_expired ||
        releaseSplashPage.release.has_programming) &&
      !releaseSplashPage.show_splash_page_when_streaming;

    if (redirectToFilmPage) {
      redirectToPath(
        getFullRouteUrl({
          url: `/films/${releaseSplashPage.release.film.slug}`,
          i18nUrlData: getI18nUrlDataFromState(currentState),
        }),
        ctx,
      );
    }

    if (!!releaseSplashPage.vanity_path) {
      if (releaseSplashPage.vanity_path !== releaseVanityPath) {
        redirectToPath(
          getFullRouteUrl({
            url: `/${releaseSplashPage.vanity_path}`,
            i18nUrlData: getI18nUrlDataFromState(currentState),
          }),
          ctx,
        );
      } else if (!releaseSplashPage.valid_for_country) {
        redirectToPath(
          getFullRouteUrl({
            url: `/films/${releaseSplashPage.release.film.slug}`,
            i18nUrlData: getI18nUrlDataFromState(currentState),
          }),
          ctx,
        );
      }
      return { releaseSplashPage };
    } else {
      return {
        errorStatusCode: 404,
      };
    }
  } catch (error) {
    if (error?.status === 404) {
      return {
        errorStatusCode: 404,
      };
    }
    Sentry.captureException(error, {
      extra: {
        releaseVanityPath: query.releaseVanityPath,
      },
    });
    throw error;
  }
};

export const Container = styled.div`
  height: 100%;
  font-weight: 500;
  font-size: 16px;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 18px;
  }
`;

export default ReleasePage;
