const MUBI_APP_STORE_ID = '626148774';
const GO_APP_STORE_ID = '1406769786';

export type Platform = 'android' | 'ios';

export const getAppStoreUrl = (platform: Platform, isGo?: boolean) => {
  if (platform === 'android') {
    if (isGo) {
      return 'https://play.google.com/store/apps/details?id=com.mubi.go';
    }
    return 'https://play.google.com/store/apps/details?id=com.mubi';
  }
  if (platform === 'ios') {
    if (isGo) {
      return `https://itunes.apple.com/app/apple-store/id${GO_APP_STORE_ID}`;
    }
    return `https://itunes.apple.com/app/apple-store/id${MUBI_APP_STORE_ID}?pt=2050088&ct=Modal&mt=8`;
  }
  return null;
};

export const getPlatformName = (platform: Platform) => {
  if (platform === 'android') {
    return 'Google Play';
  }
  if (platform === 'ios') {
    return 'App Store';
  }
  return null;
};
