import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { ReleaseSplashPage } from '@app/api/resources/Releases';

import MubiButtonLink from '@app/components/buttons/MubiButton/MubiButtonLink';
import PageSection from '@app/components/layout/PageSection';
import { ButtonProps, buttonStyle } from '@app/components/release/ReleaseCTA';

const WatchOnMubi = ({
  releaseSplashPage,
}: {
  releaseSplashPage: ReleaseSplashPage;
}) => {
  const { t } = useTranslation('release');
  return (
    <Container
      fontColor={releaseSplashPage.bg2_font_color}
      backgroundColor={releaseSplashPage.bg2_background_color}
    >
      <PageSection>
        <InnerSection>
          <Heading>{t('release:release.streaming_heading')}</Heading>
          <Body
            dangerouslySetInnerHTML={{
              __html: releaseSplashPage.watch_on_mubi_html,
            }}
          />
          <StyledButtonLink
            backgroundColor={releaseSplashPage.button_background_color}
            borderColor={releaseSplashPage.button_border_color}
            color={releaseSplashPage.button_font_color}
            hoverBackgroundColor={
              releaseSplashPage.button_hover_background_color
            }
            url={releaseSplashPage.film_url}
          >
            {t('release:release.cta.watch_on_mubi')}
          </StyledButtonLink>
        </InnerSection>
      </PageSection>
    </Container>
  );
};

const Container = styled.div<{ fontColor: string; backgroundColor: string }>`
  position: relative;
  color: #${props => props.fontColor};
  background-color: #${props => props.backgroundColor};
  padding: 38px 0;
`;

const InnerSection = styled.div`
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: 45%;
  }
`;

const Heading = styled.h2`
  margin-bottom: 25px;
  font-size: 30px;
`;

const Body = styled.div``;

const StyledButtonLink = styled(MubiButtonLink)<ButtonProps>`
  ${buttonStyle}
`;

export default WatchOnMubi;
