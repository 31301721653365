import styled from '@emotion/styled';

import {
  getAppStoreUrl,
  getPlatformName,
  Platform,
} from '@app/services/app-stores';

type BadgeProps = {
  isAndroid: boolean;
};
const Badge = styled.a<BadgeProps>`
  display: inline-block;
  background-image: ${`url('${process.env.assetPrefix}/static/images/app-badges.png')`};
  background-size: 100%;
  background-position: ${props => (props.isAndroid ? 'bottom' : 'top')};
  width: 100%;
  height: 100%;

  /* hide text */
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
`;

type AppStoreBadgeProps = {
  platform: Platform;
  isGo?: boolean;
};

const AppStoreBadge = ({ platform, isGo = false }: AppStoreBadgeProps) => {
  const url = getAppStoreUrl(platform, isGo);
  const isAndroid = platform === 'android';
  return (
    <Badge
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      isAndroid={isAndroid}
    >
      {getPlatformName(platform)}
    </Badge>
  );
};

export default AppStoreBadge;
